.zgoldLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px!important;
  img {
    margin-right: 4px !important;
  }
  svg {
    height: 14px;
    width: 14px;
    overflow: visible;
    opacity: .9;
  }
}
